@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* padding: 5px; */
    /* border: 5px solid red; */
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

* {
    font-family: "Open Sans", sans-serif;
    color: #1b1b1b;
    color: var(--darkGray);
}

:root {
    --primary: #ff5e00;
    --primary: #ffd800;
    --secondary: #ffe134;
    --red: #ff006a;
    --white: #ffffff;
    --lightGray: #eeeeee;
    --gray: #696969;
    --darkGray: #1b1b1b;

    --borderRadius: 0.5rem;
    --fontSize: 1rem;
    --headerFontSize: 1.3rem;
    --titleFontSize: 1.7rem;
    --padding: 0.5rem;
    --margin: 0.5rem;
    --thickPadding: 1rem;
    --thickMargin: 1rem;
}

/* a {
    color: inherit;
} */

.ReactModal__Content {
    background-color: #eeeeee !important;
    background-color: var(--lightGray) !important;
    border: none !important;
    width: 50vw !important;
    height: 50vh !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-right: -50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    border-radius: 0.5rem !important;
    border-radius: var(--borderRadius) !important;
    padding: 0 !important;
}

.ReactModal__Overlay {
    background-color: #47474780 !important;
    z-index: 100 !important;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.googleButton {
    background-color: white !important;
    border: 2px solid #696969 !important;
    border: 2px solid var(--gray) !important;
    border-radius: 8px;
    transition: all 0.3s ease !important;
    height: auto !important;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-family: "Roboto", sans-serif !important;
    font-size: 1.5rem !important;
}

.googleButton:hover {
    opacity: 0.7 !important;
}

.googleButton:active {
    opacity: 0.5 !important;
}

.googleIcon {
    height: 3.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
    margin-right: 0.5rem;
}

.button {
    height: 2.5rem;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
    font-size: var(--headerFontSize);
    transition: box-shadow 0.3s ease, opacity 0.3s ease;
    outline: none;
    cursor: pointer;
}

.button:hover {
    /* box-shadow: 0 0 1px 1px var(--lightGray); */
    /* transform: scale(1.05); */
    opacity: 0.7;
}

.button:active {
    /* transform: scale(0.95); */
    opacity: 0.4;
}

.button:disabled {
    cursor: default;
    opacity: 0.3;
}

.input {
    height: 2.5rem;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
    font-size: var(--headerFontSize);
    box-sizing: border-box;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    transition: box-shadow 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    outline: none;
}

.input:hover {
    /* box-shadow: 0 0 1px 1px var(--lightGray); */
    /* transform: scale(1.05); */
}

.input:focus {
    /* transform: scale(1.05); */
}

.textarea {
    /* height: 40px; */
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    box-sizing: border-box;
    font-size: 1.3rem;
    font-size: var(--headerFontSize);
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    transition: box-shadow 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    outline: none;
}

.textarea:hover {
    /* box-shadow: 0 0 1px 1px var(--lightGray); */
    /* transform: scale(1.05); */
}

.textarea:focus {
    /* transform: scale(1.05); */
}

.header {
    font-size: 1.3rem;
    font-size: var(--headerFontSize);
}

.text {
    font-size: 1rem;
    font-size: var(--fontSize);
}

.title {
    font-size: 1.7rem;
    font-size: var(--titleFontSize);
}

.wide {
    /* width: auto; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    width: 100%;
}

.thinPadding {
    padding: 0.5rem;
    padding: var(--padding);
    /* box-sizing: border-box; */
}

.thickPadding {
    padding: 1rem;
    padding: var(--thickPadding);
}

.thinBottomMargin {
    margin-bottom: 0.5rem;
    margin-bottom: var(--margin);
}

.thickBottomMargin {
    margin-bottom: 1rem;
    margin-bottom: var(--thickMargin);
}

.thickTopMargin {
    margin-top: 1rem;
    margin-top: var(--thickMargin);
}

.thinTopMargin {
    margin-top: 0.5rem;
    margin-top: var(--margin);
}

.thickHorizontalMargin {
    margin-left: 1rem;
    margin-left: var(--thickMargin);
    margin-right: 1rem;
    margin-right: var(--thickMargin);
}

.yellow {
    background-color: #ffd800;
    background-color: var(--primary);
}

.red {
    background-color: #ff006a;
    background-color: var(--red);
}

.gray {
    background-color: #eeeeee;
    background-color: var(--lightGray);
}

.white {
    background-color: #ffffff;
    background-color: var(--white);
}

.bold {
    font-weight: bold;
}

.leftSpace {
    margin-left: 0.5rem;
    margin-left: var(--margin);
}

.rounded {
    border-radius: 0.5rem;
    border-radius: var(--borderRadius);
}

.react-multi-email > input {
    /* background-color: gray; */
}

.centeredIcon {
    /* vertical-align: top; */
    /* height: var(--headerFontSize); */
}

.hoverShadow {
    transition: box-shadow 0.3s ease-in-out;
}

.hoverShadow:hover {
    box-shadow: 0 0 0.3em #afafaf, 0 0 1em #d4d4d4;
}

.flexCenter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.loaderButton > div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.noScrollBar::-webkit-scrollbar {
    /* display: none; */
    /* overflow-y: hidden; */
}

.assignmentsScrollContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    overflow: scroll;
    padding: 1em;
    margin-top: -1em;
    margin-left: -1em;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes anvil {
    0% {
        -webkit-transform: scale(1) translateY(0px);
                transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        -webkit-transform: scale(0.96) translateY(10px);
                transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        -webkit-transform: scale(1) translateY(0px);
                transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}
@keyframes anvil {
    0% {
        -webkit-transform: scale(1) translateY(0px);
                transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        -webkit-transform: scale(0.96) translateY(10px);
                transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        -webkit-transform: scale(1) translateY(0px);
                transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}
.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
            animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    /* margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px; */
}
.popup-content {
    margin: auto;
    background: var(--white);
    width: 50%;
    padding: var(--padding);
    border: none;
    box-shadow: 0 0 1em #dbdbdb, 0 0 1em #d4d4d4 !important;
    /* box-shadow: 5px 5px 5px black; */
}
.popup-arrow {
    color: rgb(255, 255, 255);
    display: none;
}

.popup-arrow > path {
    color: var(--white);
}
[role="tooltip"].popup-content {
    width: 200px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px; */
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup="tooltip"].popup-overlay {
    background: transparent;
}

.popup-button {
    padding: 0;
    border: none;
}

.react-date-picker {
    display: -webkit-inline-flex;
    display: inline-flex;
    position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    box-sizing: border-box;
}
.react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}
.react-date-picker__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    /* border: thin solid gray; */
    background-color: var(--white);
    border-radius: var(--borderRadius);
    margin-bottom: var(--margin);
}
.react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
    padding: var(--padding);
}
.react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}
.react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    font-size: var(--headerFontSize);
    box-sizing: content-box;
    -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
    border: 0;
    background: transparent;
    /* padding: 4px 6px; */
    padding: var(--padding);
}
.react-date-picker__button:enabled {
    cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
}
.react-date-picker__button svg {
    display: inherit;
}
.react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}
.react-date-picker__calendar--closed {
    display: none;
}
.react-date-picker__calendar .react-calendar {
    border-width: 3px;
    border-color: var(--darkGray);
}

/*---*/

.react-date-picker__calendar--open {
    /* top: 100% !important; */
    bottom: unset !important;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border-radius: var(--borderRadius);
    border: 5px solid var(--lightGray);
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: -webkit-flex;
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    height: 44px;
    /* margin-bottom: 1em; */
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    border-radius: var(--borderRadius);
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: var(--lightGray);
}
.react-calendar__tile--now {
    background-color: var(--lightGray);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background-color: var(--lightGray);
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: var(--secondary);
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--primary);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.privacy > h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
}
.privacy > h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1rem;
}
.privacy > h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.privacy > h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.privacy > p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.privacy {
    box-sizing: border-box;
    padding: 3rem;
}

.privacy > ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
}
.privacy > ol {
    list-style-type: decimal;
    list-style-position: inside;
}
.privacy > ul ul,
.privacy > ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
}
.privacy > ol ol,
.privacy > ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
}

