.privacy > h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
}
.privacy > h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1rem;
}
.privacy > h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.privacy > h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.privacy > p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.privacy {
    box-sizing: border-box;
    padding: 3rem;
}

.privacy > ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
}
.privacy > ol {
    list-style-type: decimal;
    list-style-position: inside;
}
.privacy > ul ul,
.privacy > ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
}
.privacy > ol ol,
.privacy > ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
}
