@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}
.popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    /* margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px; */
}
.popup-content {
    margin: auto;
    background: var(--white);
    width: 50%;
    padding: var(--padding);
    border: none;
    box-shadow: 0 0 1em #dbdbdb, 0 0 1em #d4d4d4 !important;
    /* box-shadow: 5px 5px 5px black; */
}
.popup-arrow {
    color: rgb(255, 255, 255);
    display: none;
}

.popup-arrow > path {
    color: var(--white);
}
[role="tooltip"].popup-content {
    width: 200px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px; */
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup="tooltip"].popup-overlay {
    background: transparent;
}

.popup-button {
    padding: 0;
    border: none;
}
