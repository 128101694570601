@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* padding: 5px; */
    /* border: 5px solid red; */
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

* {
    font-family: "Open Sans", sans-serif;
    color: var(--darkGray);
}

:root {
    --primary: #ff5e00;
    --primary: #ffd800;
    --secondary: #ffe134;
    --red: #ff006a;
    --white: #ffffff;
    --lightGray: #eeeeee;
    --gray: #696969;
    --darkGray: #1b1b1b;

    --borderRadius: 0.5rem;
    --fontSize: 1rem;
    --headerFontSize: 1.3rem;
    --titleFontSize: 1.7rem;
    --padding: 0.5rem;
    --margin: 0.5rem;
    --thickPadding: 1rem;
    --thickMargin: 1rem;
}

/* a {
    color: inherit;
} */

.ReactModal__Content {
    background-color: var(--lightGray) !important;
    border: none !important;
    width: 50vw !important;
    height: 50vh !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-right: -50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: var(--borderRadius) !important;
    padding: 0 !important;
}

.ReactModal__Overlay {
    background-color: #47474780 !important;
    z-index: 100 !important;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.googleButton {
    background-color: white !important;
    border: 2px solid var(--gray) !important;
    border-radius: 8px;
    transition: all 0.3s ease !important;
    height: auto !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif !important;
    font-size: 1.5rem !important;
}

.googleButton:hover {
    opacity: 0.7 !important;
}

.googleButton:active {
    opacity: 0.5 !important;
}

.googleIcon {
    height: 3.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
    margin-right: 0.5rem;
}

.button {
    height: 2.5rem;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: var(--headerFontSize);
    transition: box-shadow 0.3s ease, opacity 0.3s ease;
    outline: none;
    cursor: pointer;
}

.button:hover {
    /* box-shadow: 0 0 1px 1px var(--lightGray); */
    /* transform: scale(1.05); */
    opacity: 0.7;
}

.button:active {
    /* transform: scale(0.95); */
    opacity: 0.4;
}

.button:disabled {
    cursor: default;
    opacity: 0.3;
}

.input {
    height: 2.5rem;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: var(--headerFontSize);
    box-sizing: border-box;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    outline: none;
}

.input:hover {
    /* box-shadow: 0 0 1px 1px var(--lightGray); */
    /* transform: scale(1.05); */
}

.input:focus {
    /* transform: scale(1.05); */
}

.textarea {
    /* height: 40px; */
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    box-sizing: border-box;
    font-size: var(--headerFontSize);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    outline: none;
}

.textarea:hover {
    /* box-shadow: 0 0 1px 1px var(--lightGray); */
    /* transform: scale(1.05); */
}

.textarea:focus {
    /* transform: scale(1.05); */
}

.header {
    font-size: var(--headerFontSize);
}

.text {
    font-size: var(--fontSize);
}

.title {
    font-size: var(--titleFontSize);
}

.wide {
    /* width: auto; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    width: 100%;
}

.thinPadding {
    padding: var(--padding);
    /* box-sizing: border-box; */
}

.thickPadding {
    padding: var(--thickPadding);
}

.thinBottomMargin {
    margin-bottom: var(--margin);
}

.thickBottomMargin {
    margin-bottom: var(--thickMargin);
}

.thickTopMargin {
    margin-top: var(--thickMargin);
}

.thinTopMargin {
    margin-top: var(--margin);
}

.thickHorizontalMargin {
    margin-left: var(--thickMargin);
    margin-right: var(--thickMargin);
}

.yellow {
    background-color: var(--primary);
}

.red {
    background-color: var(--red);
}

.gray {
    background-color: var(--lightGray);
}

.white {
    background-color: var(--white);
}

.bold {
    font-weight: bold;
}

.leftSpace {
    margin-left: var(--margin);
}

.rounded {
    border-radius: var(--borderRadius);
}

.react-multi-email > input {
    /* background-color: gray; */
}

.centeredIcon {
    /* vertical-align: top; */
    /* height: var(--headerFontSize); */
}

.hoverShadow {
    transition: box-shadow 0.3s ease-in-out;
}

.hoverShadow:hover {
    box-shadow: 0 0 0.3em #afafaf, 0 0 1em #d4d4d4;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderButton > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.noScrollBar::-webkit-scrollbar {
    /* display: none; */
    /* overflow-y: hidden; */
}

.assignmentsScrollContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: scroll;
    padding: 1em;
    margin-top: -1em;
    margin-left: -1em;
}
